<template>
    <div class="component-container">
        <div :class="['vue-spoiler', { 'expanded': expanded }]" :style="{ width }">

            <div @click="toggleExpand" v-if="showTitle" class="title">
                <div v-if="arrow" class="arrow">
                    <img src="@/assets/arrow_up_black.svg" alt="arrow up">
                </div>

                <div v-if="!expanded">
                    <h4 v-if="title">{{ getTitleText }}</h4>
                    <slot v-else name="title"></slot>
                </div>

                <div v-else>
                    <h4 v-if="title || titleExpanded">{{ getTitleText }}</h4>
                    <slot v-else name="titleExpanded"></slot>
                </div>

                
            </div>

            <transition name="opacity" mode="out-in" appear>
                <div v-if="expanded" class="content" :style="{ 'max-height': height }">
                    <slot></slot>
                </div>
            </transition>

        </div>
    </div>
</template>
<script>

export default {
    name: 'Spoiler',

    data: () => {
        return {
            expanded: false,
            no_title: true
        }
    },

    props: {
        'expand': {
            default: () => false
        },
        'width': {
            default: () => '100%'
        },
        'height': {
            default: () => 'none'
        },
        'title': {
            default: () => ''
        },
        'titleExpanded': {
            default: () => ''
        },
        'uncollapsable': {
            default: () => false
        },
        'arrow': {
            default: () => true
        },
    },

    computed: {
        showTitle() {
            return !this.uncollapsable || (this.uncollapsable && !this.expanded);
        },

        getTitleText() {
            let title;
            if (this.expanded && this.titleExpanded) {
                title = this.titleExpanded;
            } else {
                title = this.title;
            }
            return title;
        },
    },

    methods: {
        toggleExpand() {
            this.expanded = !this.expanded;
            this.$emit('expanded', this.expanded);
        }
    },

    beforeMount() {
        this.expanded = this.expand;
    },

    watch: {
        expand() {
            if (this.uncollapsable) {
                this.expanded = true;
            } else {
                this.expanded = this.expand;
            }
        },
    }
}

</script>
<style lang="scss" scoped>
.component-container {

    .vue-spoiler {
        position: relative;
        max-width: $container-width;
        margin: 0 auto;
        border: 1px solid $timberGreen;

        .title {
            position: relative;
            display: flex;
            align-items: center;

            word-break: break-word;
            cursor: pointer;
            user-select: none;
        }

        .content {
            position: relative;
            overflow-y: auto;
            word-break: break-word;
        }

        .arrow {
            width: 25px;
            height: 25px;

            padding: 16px 16px;
            flex-shrink: 0;
            transform: rotate(-90deg);

            transition: transform 0.2s cubic-bezier(0.490, 0.265, 0.450, 1);

            img {
                height: 100%;
                width: 25px;
            }
        }
    }

    .vue-spoiler.expanded .arrow {
        transform: rotate(0deg);
    }


    /* Expand animation */
    .opacity-enter-active {
        transition: opacity .2s ease-out;
    }

    .opacity-leave-active {
        transition: 0;
    }

    .opacity-enter,
    .opacity-leave-to {
        opacity: 0;
    }
}</style>